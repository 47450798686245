import * as React from 'react';

import MenuItem from './menu-item';

const Menu: React.FC = () => {
  return (
    <div className="w-full lg:w-1/6 pb-8 xl:pb-0 lg:pb-0">
      <span className="font-semibold inline-block mb-2">Cem Tuncelli</span>
      <ul>
        <MenuItem href="/about" text="About" />
        <MenuItem href="/work" text="Work" />
        <MenuItem href="/projects" text="Projects" />
        <MenuItem href="/bookmarks" text="Bookmarks" />
        {/* <MenuItem href="/links" text="Links" /> */}
        {/* <MenuItem href="/quotes" text="Quotes" /> */}
        {/* <MenuItem href="/bookshelf" text="Bookshelf" />
        <MenuItem href="/people" text="People" /> */}
      </ul>
    </div>
  );
};

export default Menu;
