import * as React from 'react';
import { Link } from 'gatsby';

const MenuItem = ({ href, text }) => {
  return (
    <li className="inline xl:block lg:block pr-4 xl:pr-0 lg:pr-0">
      <Link activeStyle={{ color: '#000000' }} to={href} className="text-gray-500 text-sm">
        {text}
      </Link>
    </li>
  );
};

export default MenuItem;
