import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Menu from '../menu';

const Layout = ({ children, disableMenu }) => {
  const width = disableMenu ? 'max-w-3xl' : 'max-w-7xl';
  return (
    <>
      <div
        className={cn(
          width,
          'm-auto mt-8 xl:mt-16 lg:mt-16 mb-8 xl:mb-16 lg:mb-16 block xl:flex lg:flex flex-grow px-4 xl:px-0 lg:px-8',
        )}
      >
        {disableMenu ? <></> : <Menu />}
        <main className="flex-grow flex-shrink-0 flex-1">{children}</main>
        <footer></footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableMenu: PropTypes.bool,
};

export default Layout;
